import React from 'react'
import { Label, Icon } from 'semantic-ui-react'

const AuthorLabel = (props) => (
    <Label as='a' image>
      <img src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
      { props.name }
    </Label>
)

const CommentLabel = (props) => (
    <Label>
        <Icon name="comments"/>
        {props.count}
    </Label>
)

export { 
    AuthorLabel,
    CommentLabel
}