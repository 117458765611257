import React from "react"
import { FeedCard } from '../components/old/Cards';
import Layout from '../components/old/Layout';

export default class Blog extends React.Component {
    render() {
        return (
            <Layout tab="blog">
                <FeedCard/>
                <FeedCard/>
                <FeedCard/>
            </Layout>
        );
    }
}
