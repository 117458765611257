import React from 'react'
import { Card, List, Rail } from 'semantic-ui-react'
import { AuthorLabel, CommentLabel } from './Labels';

const FeedCard = () => (
  <Card
    fluid
    link
    header='Fast cars are the best'
    meta='Yesterday'
    description={[
      'Rick is a genius scientist whose alcoholism and reckless,',
      ' nihilistic behavior are a source of concern for his family.',
    ].join('')}
    extra={
        <List horizontal>
            <List.Item>
                <AuthorLabel name="Marek"/>
            </List.Item>
            <List.Item>
                <CommentLabel count="20"/>
            </List.Item>
        </List>
    }
  />
)

export { FeedCard };